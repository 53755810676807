import { IS_PROD } from "../requests/utils/constants";

// https://davidmathlogic.com/colorblind/#%23D53C00-%23E69F00-%23F0E442-%23009E73-%2356B4E9-%23004AAD-%237967CC-%23CC79A7-%23AD245E
export const ColorPalette: Record<string, string> = {
  red: "#D53C00", // altered color
  orange: "#E69F00",
  yellow: "#F0E442",
  green: "#009E73",
  lightBlue: "#56B4E9",
  blue: "#004AAD", // altered color
  purple: "#7967CC", // not in the color set
  magenta: "#CC79A7",
  maroon: "#AD245E", // not in the color set
  gray: "#666666",
};

export const REFRESH_INTERVAL_MILLISECONDS = 200;

const liveStripePublicKey =
  "pk_live_51OLotDAd4yW1JPREz4e7G8TeSXx7YFlSvgwrxIKECdFRN2BB0DlYBbFEfTtBhwcMYI2Q6RvxmZ1tNgs8kTgHvwDZ00LdGiQJn0";
const testStripePublicKey =
  "pk_test_51OLotDAd4yW1JPREPN4ve0KTAL0QlJce7FZHCefGL96nQdV9ugobLjCMjhLjUNEpnEQUbNbztPFsczOBrWUhFkbt008iuNdTQl";
export const STRIPE_PUBLIC_KEY = IS_PROD
  ? liveStripePublicKey
  : testStripePublicKey;

const liveStripePricingTableKey = "prctbl_1OsYH3Ad4yW1JPRE8c8pJvzx";
const testStripePricingTableKey = "prctbl_1OsZLdAd4yW1JPREl3vVLa3w";
export const STRIPE_PRICING_TABLE_KEY = IS_PROD
  ? liveStripePricingTableKey
  : testStripePricingTableKey;

export const VIDEO_REVIEW_LEAD_TIME_SECONDS = 5;
