import { usePrevious } from "@uidotdev/usehooks";
import React, { useEffect, useMemo, useState } from "react";
import { CustomSpinner } from "../components/CustomSpinner";
import {
  useMatchTypeOptions,
  useTeamOptions,
} from "../components/simple-select/simpleSelectOptions";
import { VideoPlayer } from "../components/VideoPlayer";
import {
  EventFilters,
  VideoReviewForm,
} from "../components/VideoReview/VideoReviewForm";
import { useVideoPlayerKeyboardShortcuts } from "../hooks/useKeyboardShortcuts";
import { useNavigate } from "react-router-dom";
import { useUser } from "../requests/users";
import { VIDEO_REVIEW_LEAD_TIME_SECONDS } from "../utils/constants";
import { useEventsToEventGridRowModels } from "../hooks/useEventsToEventGridRowModels";
import { VideoReviewNavigation } from "../components/VideoReview/VideoReviewNavigation";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { EventCards } from "../components/VideoReview/EventCards";
import { useVideoRef } from "../hooks/useVideoRef";
import {
  getFilteredEventFilters,
  useQueryFilteredDetailedEvents,
} from "../requests/matchEvents";
import { useMatch } from "../requests/matches";
import { useTagsQuery } from "../requests/tags";

export const VideoReview = () => {
  const [isAdvancedFilters, setIsAdvancedFilters] = useState(false);
  const [submittedEventFilters, setSubmittedEventFilters] =
    useState<EventFilters>();

  const parseFiltersFromUrl = (): EventFilters => {
    const newEventFilters: EventFilters = {
      matchId: "",
      eventId: "",
      team1Id: "",
      team2Id: "",
      possessionTeamId: "",
      matchTypeId: "",
      tags: [],
      previousActionIds: [],
      actionIds: [],
      nextActionIds: [],
      commentRegex: "",
      startDate: null,
      endDate: null,
      pitchXMin: "",
      pitchXMax: "",
      pitchYMin: "",
      pitchYMax: "",
    };

    const urlSearchParams = new URLSearchParams(window.location.search);

    // If eventId is included in the searchParams and there are other filters, we should remove it and redirect.
    if (urlSearchParams.has("eventId") && urlSearchParams.size > 1) {
      urlSearchParams.delete("eventId");
      window.location.search = urlSearchParams.toString();
      return newEventFilters;
    }

    for (const [key, value] of urlSearchParams.entries()) {
      if (key in newEventFilters) {
        const emptyValue = newEventFilters[key as keyof EventFilters];

        if (typeof emptyValue === "string") {
          // @ts-ignore - We guarantee that the typing is correct here because we check the key.
          newEventFilters[key as keyof EventFilters] = value as string;
        } else if (Array.isArray(emptyValue)) {
          // @ts-ignore - We guarantee that the typing is correct here because we check the key.
          newEventFilters[key as keyof EventFilters] = value.split(",");
        } else {
          // noinspection SuspiciousTypeOfGuard
          if (emptyValue instanceof Date || emptyValue === null) {
            // @ts-ignore - We guarantee that the typing is correct here because we check the key.
            newEventFilters[key as keyof EventFilters] = new Date(value);
          } else {
            console.error("EventFilters has an unknown type.");
          }
        }
      }
    }

    const isDefaultFilter =
      getFilteredEventFilters(newEventFilters).length === 0;
    setSubmittedEventFilters(isDefaultFilter ? undefined : newEventFilters);

    return newEventFilters;
  };

  const [eventFilters, setEventFilters] =
    useState<EventFilters>(parseFiltersFromUrl);

  const [isSearchEditMode, setIsSearchEditMode] = useState(
    () => getFilteredEventFilters(eventFilters).length === 0
  );

  // On initial load or URL change, reparse the event filters and query the events.
  useEffect(() => {
    const newEventFilters = parseFiltersFromUrl();
    // We need this locally within useEffect because the state is not guaranteed to be updated by the time this runs.
    const _isSearchEditMode =
      getFilteredEventFilters(newEventFilters).length === 0;
    setEventFilters(newEventFilters);
    if (!_isSearchEditMode) {
      setIsSearchEditMode(false);
      setSubmittedEventFilters(newEventFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  const filteredDetailedEventsQuery = useQueryFilteredDetailedEvents(
    submittedEventFilters
  );

  const events = useMemo(
    () => filteredDetailedEventsQuery?.data ?? [],
    [filteredDetailedEventsQuery?.data]
  );

  const prevEvents = usePrevious(events);
  const [currentEventIndex, setCurrentEventIndex] = useState<number | null>(0);
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !prevEvents?.length ||
      prevEvents?.length !== events?.length ||
      !events
        .map((event, index) => {
          const prevEvent = prevEvents[index];
          return (
            event.match_id === prevEvent.match_id &&
            event.match_event_id === prevEvent.match_event_id
          );
        })
        .every((x) => x)
    ) {
      setCurrentEventIndex(events.length ? 0 : null);
    }
  }, [events, prevEvents]);

  const currentEvent = events?.[currentEventIndex ?? 0];
  const currentEventStartTime = currentEvent
    ? currentEvent.event_time_in_seconds - VIDEO_REVIEW_LEAD_TIME_SECONDS
    : undefined;

  const match = useMatch(currentEvent?.match_id);

  const { video, videoRef, pauseVideo, playVideo, seekToVideo } = useVideoRef();

  const eventsToEventGridRowModel = useEventsToEventGridRowModels();
  const eventRows = eventsToEventGridRowModel(events);

  useEffect(() => {
    if (user?.organization_permissions?.video_review_enabled === false) {
      navigate("/");
    }
  }, [user, navigate]);

  // When the current event index changes, seek to video and list location.
  useEffect(() => {
    if (currentEventStartTime === undefined) {
      return;
    }

    document
      .getElementById(
        `event-card-${currentEvent?.match_id}-${currentEvent?.match_event_id}`
      )
      ?.scrollIntoView({ block: "nearest", behavior: "smooth" });

    seekToVideo(currentEventStartTime);
    playVideo();
    // Rendering on playVideo or seekToVideo changes will cause unintentional calls to this function, playing the video.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentEventIndex,
    currentEventStartTime,
    currentEvent?.match_event_id,
    currentEvent?.match_id,
    // playVideo,
    // seekToVideo,
  ]);

  useVideoPlayerKeyboardShortcuts(video);

  const teamOptions = useTeamOptions();
  const matchTypeOptions = useMatchTypeOptions();
  const tagsQuery = useTagsQuery();

  const onClickPreviousClip = () =>
    setCurrentEventIndex((currentEventId) => currentEventId! - 1);

  const onClickNextClip = () =>
    setCurrentEventIndex((currentEventId) => currentEventId! + 1);

  const onClickReplayClip = () => {
    seekToVideo(currentEventStartTime!);
    playVideo();
  };

  const onClickEventCard = (eventIndex: number) => {
    if (currentEventIndex === eventIndex) {
      onClickReplayClip();
    }

    setCurrentEventIndex(eventIndex);
  };

  if (!teamOptions || !matchTypeOptions || tagsQuery.isLoading) {
    return <CustomSpinner />;
  }

  if (isSearchEditMode) {
    return (
      <VideoReviewForm
        isAdvancedFilters={isAdvancedFilters}
        setIsAdvancedFilters={setIsAdvancedFilters}
        eventFilters={eventFilters}
        setEventFilters={setEventFilters}
        setIsSearchEditMode={setIsSearchEditMode}
      />
    );
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item xs={9}>
          {eventFilters && (
            <VideoReviewNavigation
              onClickPreviousClip={onClickPreviousClip}
              onClickReplayClip={onClickReplayClip}
              onClickNextClip={onClickNextClip}
              isPreviousClipDisabled={
                filteredDetailedEventsQuery.isLoading ||
                (currentEventIndex ?? 0) <= 0
              }
              isReplayClipDisabled={!events.length}
              isNextClipDisabled={
                filteredDetailedEventsQuery.isLoading ||
                (events?.length ?? 0) === 0 ||
                currentEventIndex === events.length - 1
              }
              setIsSearchEditMode={setIsSearchEditMode}
            />
          )}
        </Grid>
        <Grid container direction="row">
          <Grid item xs={9}>
            <Box sx={{ m: 1 }}>
              <VideoPlayer
                // Use a key so that video re-renders with proper start time on match change.
                key={match?.id}
                videoUrl={match?.video_url ?? ""}
                videoStartTime={currentEventStartTime}
                ref={videoRef}
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <EventCards
              eventRows={eventRows}
              currentEventIndex={currentEventIndex}
              onClickEventCard={onClickEventCard}
              pauseVideo={pauseVideo}
            />
          </Grid>
        </Grid>
      </Grid>
      {filteredDetailedEventsQuery.isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "gray",
            opacity: 0.6,
            cursor: "wait",
          }}
        />
      )}
    </>
  );
};
