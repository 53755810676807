import { renderTime } from "../utils/time";
import { getDisplayDate } from "../utils/getDisplayDate";
import { EventGridRowModel } from "../components/EventGrid";
import { useGetTeam } from "../requests/teams";
import { useActionTypes } from "../requests/actionTypes";
import { useMatches } from "../requests/matches";
import { DetailedEvent } from "../requests/matchEvents";

export const useEventsToEventGridRowModels = (): ((
  events: DetailedEvent[]
) => EventGridRowModel[]) => {
  const actionTypes = useActionTypes();
  const getTeam = useGetTeam();
  const matches = useMatches();

  return (events: DetailedEvent[]) =>
    events
      .filter((event) => !event.is_deleted)
      .map((event) => {
        const match = matches?.find((_match) => _match.id === event.match_id);
        return {
          ...event,
          time: renderTime(event.event_time_in_seconds),
          action: actionTypes[event.action_type_id],
          possession_team: getTeam(event.begin_team_id),
          opposing_team: getTeam(
            event.begin_team_id === match!.away_team_id
              ? match!.home_team_id
              : match!.away_team_id
          ),
          match_date: getDisplayDate(match!.start_time),
          tags: event.tags,
        } as EventGridRowModel;
      });
};
