import React, { useState } from "react";
import { CommentModal } from "./Tracker/CommentModal";
import CommentIcon from "@mui/icons-material/Comment";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { LockableIconButton } from "./LockableIconButton";
import { DetailedEvent } from "../requests/matchEvents";

type CommentInputProps = {
  event: DetailedEvent;
  isActiveEvent: boolean;
  // On click, pause the video to prevent the video from playing while editing tags.
  pauseVideo: () => void;
};

export const CommentInput = ({
  event,
  isActiveEvent,
  pauseVideo,
}: CommentInputProps) => {
  const [commentEvent, setCommentEvent] = useState<DetailedEvent | null>(null);
  const disabled = !isActiveEvent;
  return (
    <>
      <CommentModal
        event={commentEvent}
        setEventNull={() => setCommentEvent(null)}
      />
      <LockableIconButton
        lockMessage="Cannot edit comment while another event is selected."
        isLocked={disabled}
        onClick={() => {
          pauseVideo();
          setCommentEvent(event);
        }}
      >
        <span>
          {event.comment ? (
            <CommentIcon
              color="primary"
              sx={disabled ? { opacity: "66%" } : {}}
            />
          ) : (
            <AddCommentOutlinedIcon />
          )}
        </span>
      </LockableIconButton>
    </>
  );
};
