import { Auth } from "aws-amplify";
import { API_DOMAIN, HTTP_STATUS_NO_CONTENT } from "./constants";

export enum HttpRequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

const ALERT_DELAY_MILLISECONDS = 30000;

let readyToAlert: boolean = true;
const limitedAlert = (message: any): void => {
  if (!readyToAlert) {
    return;
  }

  readyToAlert = false;
  alert(message);
  setTimeout(() => {
    readyToAlert = true;
  }, ALERT_DELAY_MILLISECONDS);
};

export const send = async <T>(
  pathname: string,
  method: HttpRequestMethod,
  body?: object
): Promise<T> => {
  const jwt = await (async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken())();

  const init: RequestInit = {
    method,
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    ...(body && { body: JSON.stringify(body) }),
  };

  const response = await fetch(API_DOMAIN + pathname, init);
  if (!response.ok) {
    limitedAlert("Refresh the browser. Request failed.");
    throw new Error(response.statusText);
  }

  if (response.status === HTTP_STATUS_NO_CONTENT) {
    // A boolean or undefined type `T` should always be requested for NO_CONTENT responses.
    return response.ok as unknown as T;
  }

  return response.json();
};
