import { EventGridRowModel } from "../../components/EventGrid";
import React, { ReactElement } from "react";
import { CommentInput } from "../../components/CommentInput";

import { DetailedEvent } from "../../requests/matchEvents";

export const useCommentColumn = (
  activeEvent: DetailedEvent | null,
  pauseVideo: () => void
): ((row: EventGridRowModel) => ReactElement) => {
  return (row: EventGridRowModel) => (
    <CommentInput
      event={row}
      isActiveEvent={
        activeEvent?.match_id === row.match_id &&
        activeEvent?.match_event_id === row.match_event_id
      }
      pauseVideo={pauseVideo}
    />
  );
};
