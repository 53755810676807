import { Button } from "@mui/material";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import ReplayIcon from "@mui/icons-material/Replay";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  onClickPreviousClip: () => void;
  onClickReplayClip: () => void;
  onClickNextClip: () => void;
  isPreviousClipDisabled: boolean;
  isReplayClipDisabled: boolean;
  isNextClipDisabled: boolean;
  setIsSearchEditMode: Dispatch<SetStateAction<boolean>>;
};

export const VideoReviewNavigation = ({
  onClickPreviousClip,
  onClickReplayClip,
  onClickNextClip,
  isPreviousClipDisabled,
  isReplayClipDisabled,
  isNextClipDisabled,
  setIsSearchEditMode,
}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        onClick={() => {
          setIsSearchEditMode(true);
          navigate("/video-review");
        }}
        variant="contained"
        color="info"
        sx={{ m: 1 }}
      >
        New Search
      </Button>
      <Button
        onClick={() => setIsSearchEditMode(true)}
        variant="contained"
        color="info"
        sx={{ m: 1 }}
      >
        Modify Search
      </Button>
      <Button
        onClick={onClickPreviousClip}
        startIcon={<SkipPreviousIcon />}
        variant="contained"
        sx={{ m: 1 }}
        disabled={isPreviousClipDisabled}
      >
        Previous
      </Button>
      <Button
        onClick={onClickReplayClip}
        startIcon={<ReplayIcon />}
        variant="contained"
        sx={{ m: 1 }}
        disabled={isReplayClipDisabled}
      >
        Replay
      </Button>
      <Button
        onClick={onClickNextClip}
        endIcon={<SkipNextIcon />}
        variant="contained"
        sx={{ m: 1 }}
        disabled={isNextClipDisabled}
      >
        Next
      </Button>
    </>
  );
};
