import styled from "@emotion/styled";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";

export type VideoPlayerProps = {
  videoUrl: string;
  videoStartTime?: number;
};

export type VideoPlayerRef =
  | (Partial<ReactPlayer> & {
      isPlaying: boolean;
      play: () => void;
      pause: () => void;
      toggle: () => void;
      getTime: () => number;
      seekTo: (time: number) => void;
      skipForward: (seconds: number) => void;
    })
  | null;

export const ReactPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

export const VideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(
  ({ videoUrl, videoStartTime }, ref) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef<ReactPlayer>(null);

    useImperativeHandle(
      ref,
      () => {
        const getTime = () => videoRef.current?.getCurrentTime() ?? 0;
        const seekTo = (time: number) => videoRef.current?.seekTo(time);

        return {
          ...videoRef.current,
          isPlaying,
          play: () => setIsPlaying(true),
          pause: () => setIsPlaying(false),
          toggle: () => setIsPlaying((prevState) => !prevState),
          getTime,
          seekTo,
          skipForward: (seconds: number) => seekTo(getTime() + seconds),
        };
      },
      // eslint-disable-next-line
      [videoRef.current, isPlaying]
    );

    return (
      <ReactPlayerWrapper>
        <ReactPlayer
          style={{ position: "absolute", top: 0, left: 0 }}
          ref={videoRef}
          url={videoUrl}
          playing={isPlaying}
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
          onSeek={() => setIsPlaying(true)}
          config={{
            youtube: {
              playerVars: {
                start: videoStartTime,
                // disables keyboard shortcuts; we manually enable some of them
                disablekb: 1,
              },
            },
          }}
          controls
          width="100%"
          height="100%"
        />
      </ReactPlayerWrapper>
    );
  }
);
