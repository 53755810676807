import { Box, Button, darken, lighten, Stack } from "@mui/material";
import assert from "assert";
import React, { useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useInterval } from "usehooks-ts";
import { CustomSpinner } from "../components/CustomSpinner";

import { InnerMarginBox } from "../components/InnerMarginBox";
import pitch from "../components/rugbyPitch";
import { ActionButtons } from "../components/Tracker/ActionButtons";
import { Dot } from "../components/Tracker/Dot";
import { Possession } from "../components/Tracker/Possession";
import { VideoPlayer } from "../components/VideoPlayer";
import { useAwayTeam, useGetTeam, useHomeTeam } from "../requests/teams";
import {
  Action,
  DatabaseAction,
  ExpandedAction,
} from "../business-logic/ActionBehavior/Action";
import { ACTION_CONFIGS } from "../utils/actionMappings";
import {
  ColorPalette,
  REFRESH_INTERVAL_MILLISECONDS,
} from "../utils/constants";
import { getLargeRandomInt } from "../utils/getLargeRandomInt";
import EventGrid, { EventGridRowModel } from "../components/EventGrid";
import {
  useActionKeyboardShortcuts,
  useVideoPlayerKeyboardShortcuts,
} from "../hooks/useKeyboardShortcuts";
import { NonDatabaseActionActionConfig } from "../business-logic/ActionBehavior/ActionConfig/NonDatabaseActionActionConfig/NonDatabaseActionActionConfig";
import { useVideoRef } from "../hooks/useVideoRef";
import { useActionTypes } from "../requests/actionTypes";
import {
  DetailedEvent,
  PostMatchEventResponse,
  useCreateDetailedEvent,
  useDeleteDetailedEvent,
  useQueryMatchDetailedEvents,
} from "../requests/matchEvents";
import { Match, useMatch } from "../requests/matches";

const PERCENT_VIDEO_X = 55;
// subtract 3 to buffer for margins
const PERCENT_PITCH_X = 100 - PERCENT_VIDEO_X - 3;

type LocationState = {
  match: Match;
};

export type TrackerParams = {
  matchId: string;
};

export const Tracker = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;

  const params = {
    matchId: parseInt(useParams<TrackerParams>().matchId ?? "0"),
  };
  const matchId = params.matchId;

  const match = useMatch(matchId) ?? locationState?.match;
  const actionTypes = useActionTypes();
  const getTeam = useGetTeam();
  const homeTeam = useHomeTeam(matchId);
  const awayTeam = useAwayTeam(matchId);

  const [isOverride, setIsOverride] = useState(false);

  const [isActionButtonsEnabled, setIsActionButtonsEnabled] = useState(true);
  useState(true);
  const [selectedAction, setSelectedAction] = useState<Action | null>(null);

  const [tagsToAddOnPitchClick, setTagsToAddOnPitchClick] = useState<
    string[] | null
  >(null);
  const disablePitchClick = () => setTagsToAddOnPitchClick(null);
  const setEnablePitchClick = (tags: string[]) =>
    setTagsToAddOnPitchClick(tags);
  const isPitchEnabled = tagsToAddOnPitchClick !== null;

  const [pseudoEventActions, setPseudoEventActions] = useState<
    Set<ExpandedAction>
  >(new Set());

  const { video, videoRef, pauseVideo, playVideo, getTimeVideo, seekToVideo } =
    useVideoRef();

  const offscreenDivRef = useRef<HTMLDivElement>(null);
  useInterval(() => {
    if (
      document.activeElement ===
      videoRef.current?.getInternalPlayer?.()?.getIframe()
    ) {
      offscreenDivRef.current?.focus();
    }
  }, 50);

  const pitchRef = useRef<HTMLImageElement>(null);

  const { data: events = [], isLoading: eventsIsLoading } =
    useQueryMatchDetailedEvents(matchId);

  const deleteEvent = useDeleteDetailedEvent().mutate;
  const createEventRequest = useCreateDetailedEvent().mutateAsync;
  const createEvent = (
    action: DatabaseAction,
    possessionTeamId: number,
    locX: number,
    locY: number,
    tags: string[] = []
  ): Promise<PostMatchEventResponse> => {
    return createEventRequest({
      match_id: matchId,
      match_event_id: getLargeRandomInt(),
      action_type_id: action,
      begin_team_id: possessionTeamId,
      end_team_id: possessionTeamId,
      event_time_in_seconds: Math.round(currentVideoTime),
      loc_x: locX,
      loc_y: locY,
      is_deleted: false,
      tags,
    });
  };

  const orderedActiveEvents = events
    .filter((event) => !event.is_deleted)
    .sort(
      (a, b) =>
        b.event_time_in_seconds - a.event_time_in_seconds ||
        b.match_event_id - a.match_event_id
    );

  // orderedActiveEvents has the most recent event first in the array
  const [currentVideoTime, setCurrentVideoTime] = useState<number>(
    orderedActiveEvents?.[0]?.event_time_in_seconds ?? 0
  );
  const timeFilteredActiveEvents = orderedActiveEvents.filter(
    // This is non-inclusive of equal to the current time because it prevents the buttons from flashing the wrong
    // validity. I haven't put in the effort to figure out exactly why this is.
    (event) => event.event_time_in_seconds < currentVideoTime
  );
  const currentEvent: DetailedEvent | undefined = timeFilteredActiveEvents?.[0];

  const mostRecentEventPossessionTeamId = Number(
    currentEvent?.begin_team_id ?? match?.home_team_id
  );

  // ReactPlayer has resolved the onProgress strict mode bug, but this is still a more straightforward method of setting
  // the current time.
  useInterval(
    () => setCurrentVideoTime(getTimeVideo() ?? 0),
    REFRESH_INTERVAL_MILLISECONDS
  );

  const deleteEvents = (deletedMatchEventIds: number[]): void => {
    if (!deletedMatchEventIds.length) {
      return;
    }

    // TODO: Make this a single call so that it doesn't make separate requests and state updates for each event.
    deletedMatchEventIds.forEach((matchEventId) =>
      deleteEvent({ matchId, matchEventId })
    );
  };

  const onClickTime = (row: EventGridRowModel) =>
    seekToVideo(row.event_time_in_seconds);

  const onActionButtonClick = (action: Action, tags: string[]) => {
    setIsOverride(false);
    setIsActionButtonsEnabled(false);

    if (!(ACTION_CONFIGS[action] instanceof NonDatabaseActionActionConfig)) {
      setSelectedAction(action);
    }

    if (
      ACTION_CONFIGS[action].getRequiresPitchClick(timeFilteredActiveEvents)
    ) {
      pauseVideo();
      setEnablePitchClick(tags);
    } else {
      assert(homeTeam && awayTeam);
      const createEventWithTags = (
        action: DatabaseAction,
        possessionTeamId: number,
        locX: number,
        locY: number
      ) => createEvent(action, possessionTeamId, locX, locY, tags);

      ACTION_CONFIGS[action]
        .doAction(
          timeFilteredActiveEvents,
          createEventWithTags,
          pseudoEventActions,
          setPseudoEventActions,
          match
        )
        .then((_) => setIsActionButtonsEnabled(true));
    }
  };

  const onRemoveSelectedAction = () => {
    setSelectedAction(null);
    disablePitchClick();
    setIsActionButtonsEnabled(true);
    playVideo();
  };

  useVideoPlayerKeyboardShortcuts(video);
  useActionKeyboardShortcuts(
    isActionButtonsEnabled,
    timeFilteredActiveEvents,
    pseudoEventActions,
    onActionButtonClick,
    selectedAction !== null,
    onRemoveSelectedAction
  );

  if (
    !match ||
    !homeTeam ||
    !awayTeam ||
    !orderedActiveEvents ||
    eventsIsLoading
  ) {
    return <CustomSpinner />;
  }

  const onPitchClick = (e: React.MouseEvent<HTMLImageElement>) => {
    disablePitchClick();
    playVideo();

    assert(selectedAction !== null);
    const locX = Math.round(
      pitch.getXCoordinate(e.nativeEvent.offsetX, e.currentTarget.offsetWidth)
    );
    const locY = Math.round(
      pitch.getYCoordinate(e.nativeEvent.offsetY, e.currentTarget.offsetHeight)
    );

    assert(isPitchEnabled);
    const createEventWithTags = (
      action: DatabaseAction,
      possessionTeamId: number,
      locX: number,
      locY: number
    ) =>
      createEvent(action, possessionTeamId, locX, locY, tagsToAddOnPitchClick);

    ACTION_CONFIGS[selectedAction]
      .doAction(
        timeFilteredActiveEvents,
        createEventWithTags,
        pseudoEventActions,
        setPseudoEventActions,
        match,
        locX,
        locY
      )
      .then((_) => setIsActionButtonsEnabled(true));
  };

  const isPossessionClickDisabled =
    selectedAction !== null ||
    (currentEvent !== undefined &&
      !ACTION_CONFIGS[currentEvent?.action_type_id]?.nextActions?.includes(
        DatabaseAction.kickoff
      ) &&
      !isOverride);

  // This is not used for the "Possession Change" button, but for the "Home Team Possession" and "Away Team Possession" buttons.
  const onPossessionClick = (isHomeTeamPossessionClicked: boolean) =>
    setPseudoEventActions((prevState) => {
      const newState = new Set(prevState);
      isHomeTeamPossessionClicked &&
      mostRecentEventPossessionTeamId === match.home_team_id
        ? newState.delete(ExpandedAction.possessionChange)
        : newState.add(ExpandedAction.possessionChange);
      return newState;
    });

  const clickMessage = isPitchEnabled
    ? selectedAction !== null && (
        <>
          <div>
            Click where the{" "}
            <strong>
              {ACTION_CONFIGS[selectedAction].getClickPhrase(actionTypes)}
            </strong>{" "}
            by{" "}
            <strong>
              {
                getTeam(
                  ACTION_CONFIGS[selectedAction].getIsHomeTeamPossession(
                    timeFilteredActiveEvents,
                    match,
                    pseudoEventActions.has(ExpandedAction.possessionChange)
                  )
                    ? homeTeam.id
                    : awayTeam.id
                )?.name
              }
            </strong>{" "}
            occurred.
          </div>
        </>
      )
    : currentEvent && (
        <>
          <div>
            Last Event:{" "}
            <strong>{actionTypes[currentEvent.action_type_id]}</strong> by{" "}
            <strong>{getTeam(currentEvent.begin_team_id)?.name}</strong>
          </div>
        </>
      );

  return (
    <Stack direction="row">
      {/*This allows us to "focus" on this element so that we aren't focused on the ReactPlayer and can use keyboard shortcuts.*/}
      <div
        ref={offscreenDivRef}
        tabIndex={-1}
        style={{ position: "absolute", left: "-9999px" }}
      />
      <InnerMarginBox width={`${PERCENT_VIDEO_X}%`}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <VideoPlayer
            ref={videoRef}
            videoUrl={match.video_url}
            videoStartTime={currentVideoTime}
          />
          <br />
          <EventGrid
            events={orderedActiveEvents}
            currentEvent={currentEvent}
            deleteEvents={deleteEvents}
            onClickTime={onClickTime}
            pauseVideo={pauseVideo}
          />
        </div>
      </InnerMarginBox>
      <InnerMarginBox width={`${PERCENT_PITCH_X}%`}>
        <Box position="relative" width="100%">
          <img
            src={pitch.image}
            ref={pitchRef}
            onClick={isPitchEnabled ? onPitchClick : undefined}
            style={{
              cursor: isPitchEnabled ? "crosshair" : undefined,
              backgroundColor: "gray",
              opacity: isPitchEnabled ? 1 : 0.25,
              width: "100%",
            }}
            alt="pitch"
          />
          {currentEvent && (
            <Dot
              color={getTeam(currentEvent.begin_team_id)?.color ?? "black"}
              currentEvent={currentEvent}
              pitchRef={pitchRef}
            />
          )}
        </Box>
        <div style={{ height: "1em" }}>
          {isActionButtonsEnabled || isPitchEnabled ? clickMessage : ""}
        </div>
        <ActionButtons
          isActionButtonsEnabled={isActionButtonsEnabled}
          previousEvents={timeFilteredActiveEvents}
          pseudoEventActions={pseudoEventActions}
          onActionButtonClick={(action: Action) =>
            onActionButtonClick(action, [])
          }
          homeTeam={homeTeam}
          awayTeam={awayTeam}
        />
        <div id="generic-div" contentEditable="true">
          {/* Editable Content */}
        </div>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
            margin: "1em 2em",
          }}
        >
          <Possession
            match={match}
            isHomeTeamPossession={
              pseudoEventActions.has(ExpandedAction.possessionChange)
                ? mostRecentEventPossessionTeamId !== match.home_team_id
                : mostRecentEventPossessionTeamId === match.home_team_id
            }
            onClick={onPossessionClick}
            disabled={isPossessionClickDisabled}
          />
          <Button
            variant="contained"
            sx={{
              background: lighten(ColorPalette.red, 0.2),
              "&:hover": {
                backgroundColor: darken(ColorPalette.red, 0.5),
              },
            }}
            onClick={() =>
              ACTION_CONFIGS[ExpandedAction.override]
                .doAction(
                  timeFilteredActiveEvents,
                  createEvent,
                  pseudoEventActions,
                  setPseudoEventActions,
                  match
                )
                .then((_) => setIsActionButtonsEnabled(true))
            }
          >
            Override
          </Button>
        </Box>
      </InnerMarginBox>
    </Stack>
  );
};
