import { useEventListener } from "usehooks-ts";
import { VideoPlayerRef } from "../components/VideoPlayer";
import {
  Action,
  ExpandedAction,
} from "../business-logic/ActionBehavior/Action";
import { ACTION_CONFIGS } from "../utils/actionMappings";

import { DetailedEvent } from "../requests/matchEvents";
import { useKeyboardShortcuts } from "../requests/keyboardShortcuts";
import { TAG_PREVIOUS_EVENT } from "../components/Account/KeyboardShortcutGrid";
import { useAddTagsSync } from "../requests/tags";

const isTextBoxEvent = (e: KeyboardEvent) =>
  e?.target instanceof HTMLTextAreaElement ||
  e?.target instanceof HTMLInputElement;

export const useVideoPlayerKeyboardShortcuts = (video: VideoPlayerRef) => {
  useEventListener("keydown", (e: KeyboardEvent) => {
    // Don't trigger keyboard shortcuts if typing in a text box.
    if (isTextBoxEvent(e)) {
      return;
    }

    switch (e.key) {
      case " ":
        video?.toggle();
        // This prevents the space bar key from scrolling down
        e.preventDefault();
        break;
      case "ArrowLeft":
        video?.skipForward(-5);
        break;
      case "ArrowRight":
        video?.skipForward(5);
        break;
      case "/":
        video?.skipForward(30);
        break;
      case ".":
        video?.skipForward(-15);
        break;
    }
  });
};

export const useActionKeyboardShortcuts = (
  isActionButtonsEnabled: boolean,
  previousEvents: DetailedEvent[],
  pseudoEventActions: Set<ExpandedAction>,
  onActionButtonClick: (action: Action, tags: string[]) => void,
  isSelectedAction: boolean,
  removeSelectedAction: () => void
) => {
  const customKeyboardShortcuts = useKeyboardShortcuts();
  const addTags = useAddTagsSync();

  const handlePreviousEventKeyboardShortcuts = (e: KeyboardEvent) => {
    // Handle shortcuts for previous event.
    const previousEventShortcuts =
      customKeyboardShortcuts?.filter(
        (shortcut) => shortcut.action === TAG_PREVIOUS_EVENT
      ) ?? [];
    const keyboardShortcut = previousEventShortcuts.find(
      (previousEventKeyboardShortcut) =>
        previousEventKeyboardShortcut.shortcut === e.key
    );
    if (
      keyboardShortcut &&
      previousEvents.length > 0 &&
      keyboardShortcut.tags.length > 0
    ) {
      addTags(
        previousEvents[0],
        keyboardShortcut.tags.map((tag) => tag.value)
      );
    }
  };

  const handleNewEventKeyboardShortcuts = (e: KeyboardEvent) => {
    // Handle shortcuts for creating new event.
    const newEventShortcuts =
      customKeyboardShortcuts?.filter(
        (shortcut) => shortcut.action !== TAG_PREVIOUS_EVENT
      ) ?? [];
    const keyboardShortcut = newEventShortcuts.find(
      (newEventShortcut) => newEventShortcut.shortcut === e.key
    );
    if (
      keyboardShortcut &&
      isActionButtonsEnabled &&
      newEventShortcuts.length > 0 &&
      ACTION_CONFIGS[keyboardShortcut.action as Action]?.isValid(
        previousEvents,
        pseudoEventActions,
        ACTION_CONFIGS
      )
    ) {
      onActionButtonClick(
        keyboardShortcut.action as Action,
        keyboardShortcut.tags.map((tag) => tag.value)
      );
    }
  };

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (isTextBoxEvent(e)) {
      return;
    }

    if (e.key === "Escape" && isSelectedAction) {
      removeSelectedAction();
      return;
    }

    handlePreviousEventKeyboardShortcuts(e);
    handleNewEventKeyboardShortcuts(e);
  });
};
